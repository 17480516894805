






























import { Vue, Component } from "vue-property-decorator";
import { organisationModule } from "@/store/modules/OrganisationModule";
import { OrganisationDetails } from "@/models/OrganisationDetails";
import { OrganisationUnit } from "../models/OrganisationUnit";
import "vuetify/dist/vuetify.min.css";

@Component
export default class OrganisationUnits extends Vue {
  private items: any[] = [];

  private get organisation(): OrganisationDetails {
    return organisationModule.organisation;
  }

  private createDataTree(orgUnits: OrganisationUnit[]): any[] {
    const hashTable = Object.create(null);
    orgUnits.forEach(
      (aData) => (hashTable[aData.id] = { ...aData, children: [] })
    );
    const dataTree = [];
    orgUnits.forEach((aData) => {
      if (aData.parentId)
        hashTable[aData.parentId].children.push(hashTable[aData.id]);
      else dataTree.push(hashTable[aData.id]);
    });
    return dataTree;
  }

  private activated() {
    this.items = this.createDataTree(organisationModule.organisation.orgUnits);
  }
}
